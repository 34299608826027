/**
 * v-renewal
 * 是否需要续费
 * 接收参数：function类型
 */
import type { Directive, DirectiveBinding } from 'vue'
import { GlobalStore } from '@/store'
import { ElMessageBox } from 'element-plus'

 interface ElType extends HTMLElement {
	 __handleClick__: () => any;
 }

const debounce: Directive = {
	 mounted(el: ElType, binding: DirectiveBinding) {
		 if (typeof binding.value !== 'function') {
			 // eslint-disable-next-line no-throw-literal
			 throw 'callback must be a function'
		 }
		 el.__handleClick__ = function() {
      const globalStore = GlobalStore()
      if (globalStore.userInfo.overtimeFlag === '1') {
        ElMessageBox.confirm('为了保障您的业务开展，请联系您的销售人员进行签约或缴费；您也可以通过拨打客服热线进行咨询。', '您的系统已到期！', {
          confirmButtonText: '立即续费',
          cancelButtonText: '知道了',
          type: 'warning',
          center: true
        }).then(() => {
          location.href = '#/account/products'
        })
      } else {
        binding.value()
      }
		 }
		 el.addEventListener('click', el.__handleClick__)
	 },
	 beforeUnmount(el: ElType) {
		 el.removeEventListener('click', el.__handleClick__)
	 }
}

export default debounce

